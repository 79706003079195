module Main exposing (main)

import Date exposing (toIsoString, Date)
import Time exposing (Month(..))
import Dates exposing (..)
import Strings exposing (..)
import KPOs exposing (KPO, getKPOs)
import List
import Html.Styled exposing (..)
import Style exposing (styledTable)
import Style exposing (styledTableRow)
import Task
import Browser
import Html
import Css
import Css.Global
import Style exposing (styledTableHeader)
import Html.Styled.Attributes exposing (css)
import Style exposing (styledTitle)
import Style exposing (..)

type alias Model =
  Date

type Msg =
  GotDate Date

update : Msg -> Model -> (Model, Cmd Msg)
update (GotDate date) _ =
  (date, Cmd.none)

view : Model -> Html msg
view model =
  div [ ]
      [ styledTitle [ css [ Css.textAlign Css.center]] [ text "🔥 KPO Counter 🔥" ]
      , table getKPOs model
      ]

main =
  Browser.element
    {
      init = \() -> (Date.fromCalendarDate 1970 Jan 1, Date.today |> Task.perform GotDate),
      update = update,
      view = view >> toUnstyled,
      subscriptions = \_ -> Sub.none
    }

table : List KPO -> Date -> Html msg
table kpos today =
  styledTable [] (tableHead :: List.map (toTableRow today) kpos)


tableHead : Html msg
tableHead =
    styledTableHeader []
        [ th [] [ text "Name" ]
        , th [] [ text "Signed" ]
        , th [] [ text "Days to riches" ]
        , th [] [ text "Next milestone in..." ]
        ]


toTableRow : Date -> KPO ->  Html msg
toTableRow today kpo =
  let
    daysLeft = daysUntilRelease today kpo.startDate
  in
    styledTableRow []
        [ td [] [ kpo.name |> text ]
        , td [] [ kpo.startDate |> toIsoString |> text ]
        , td [] [ daysLeft |> String.fromInt |> text ]
        , td [] [  (daysLeft - nextMilestone daysLeft) |> countdownWithCelebration |> text ]
        ]
